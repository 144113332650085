@import "~@uw-it-sis/lib-react/lib/scss/index";

.text-muted {
	color: #707070 !important;
}

.app-wrapper .app-content .content {
	min-height: 500px !important;
}

.sectionCode {
	font-weight: 600;

	.code {
		min-width: 2rem;

	}
}

.intro-paragraph {
	font-size: 1.25rem;
}

.form-check {
	padding-left: 1.5rem;

	.form-check-input {
		margin-left: -1.5rem;
	}
}

.card-clickable {
	cursor: pointer;
	&.active {
		cursor: default;
	}

	&:not(.active) {
		opacity: .75;
	}

	&:not(.active):hover,
	&:not(.active):focus {
		color: $body-color !important;
		opacity: 1;
		@extend .shadow;
		@extend .bg-purple-light;
		@extend .border-purple;
	}

	.card-header {
		background-color: rgba(0, 0, 0, 0.05);
	}
}